export function isIE() {
  var ua = navigator.userAgent;
  /* MSIE used to detect old browsers and Trident used to newer ones*/
  var is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
  return is_ie;
}

export const isSafari =
  navigator.vendor &&
  navigator.vendor.indexOf("Apple") > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf("CriOS") === -1 &&
  navigator.userAgent.indexOf("FxiOS") === -1;

export const rngNum = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
}

export const prefetch_file = (url, callback) => {
  let xhr = new XMLHttpRequest();

  xhr.open("GET", url);
  xhr.responseType = "blob";

  xhr.send();

  const toSlow = setTimeout(() => {
    return callback(2);
  }, 2850);

  xhr.onload = () => {
    if (xhr.status !== 200) {
      // console.log(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
    } else {
      // console.log(`Done, got ${xhr.response} bytes`); // response is the server
      return clearTimeout(toSlow);
    }
  };

  xhr.onprogress = (event) => {
    if (event.lengthComputable) {
      // console.log(`Received ${event.loaded} of ${event.total} bytes`);
    } else {
      // console.log(`Received ${event.loaded} bytes`); // no Content-Length
    }
  };

  xhr.onerror = () => {
    // console.log("Request failed");
  };
};